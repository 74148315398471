import { components } from 'react-select'
import { Badge, Divider, Tooltip, Typography } from 'maple-storybook'
import { Close, FileDownloadOutlined, Search } from 'maple-storybook/assets'

import { Button } from 'components'
import ItemGrid from 'components/Grid/ItemGrid'

export const checkAllOption = (array) => (Array.isArray(array) ? array.some((obj) => obj?.value === '*') : false)

export const ValueContainer = ({ children, ...props }) => {
  let values = children[0]

  let isDefaultLabel = props.selectProps.labelDefault

  if (props.isMulti && props.hasValue) {
    let childVals = children[0]
    let hasAllOption = checkAllOption(props.selectProps.value)
    let createSelect = props.selectProps.createSelect
    let defaultToShow = props.selectProps.defaultToShow
    let isCSV = props.selectProps.isCSV

    if (Array.isArray(childVals)) {
      values = isCSV ? (
        <Typography color="#667085" variant="text-sm" type="normal">
          Select CSV attributes
        </Typography>
      ) : (
        <>
          {!isDefaultLabel ? (
            createSelect ? (
              childVals.slice(0, defaultToShow)
            ) : (
              <Badge
                size="lg"
                variant="primary"
                label={hasAllOption ? 'All' : childVals[childVals.length - 1].props.data.label}
              />
            )
          ) : (
            <span>{props.selectProps.placeholder}&nbsp;</span>
          )}
          {(childVals.length > (createSelect ? defaultToShow : 1) || isDefaultLabel) && !hasAllOption && (
            <Tooltip
              variant="light"
              title={
                <ul style={{ paddingInlineStart: '20px', margin: 0 }}>
                  {childVals.map(
                    (value, index) => index !== childVals.length - 1 && <li key={index}>{value.props.data.label}</li>
                  )}
                </ul>
              }>
              <Badge
                className="ml-half"
                size="lg"
                variant={createSelect ? 'gray' : 'primary'}
                label={
                  <>
                    &nbsp;{!isDefaultLabel && '+'}
                    {hasAllOption
                      ? isDefaultLabel
                        ? childVals.length - 1
                        : childVals.length - 2
                      : isDefaultLabel
                        ? childVals.length
                        : childVals.length - (createSelect ? defaultToShow : 1)}
                  </>
                }
              />
            </Tooltip>
          )}
        </>
      )
    }
  }

  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!props.isMulti ? (
          <div className="d-grid align-items-center">
            {!!children && !props.isMulti && props.selectProps.searchIcon && (
              <Search className="searchIcon mr-half" fill="#667085" fontSize="1.3" />
            )}
            {children}
          </div>
        ) : (
          <>
            {values}
            {children[1]}
          </>
        )}
      </components.ValueContainer>
    )
  )
}

const checkCurrentOption = (status, option) => status && option.props.value !== '*' && option.props.value

const getOptionTypes = (children, status) =>
  Array.isArray(children)
    ? children?.filter((option) =>
        checkCurrentOption(status ? option.props.isSelected : !option.props.isSelected, option)
      )
    : children

export const MenuList = ({ children, selectProps, ...rest }) => {
  return Array.isArray(children) ? (
    <ItemGrid className="d-flex flex-column align-items-center pd-0">
      <components.MenuList {...rest}>
        {selectProps.allCheck && children[0]?.props?.label === 'All' && children[0]}
        {!selectProps.unSortedList ? (
          <>
            {rest.hasValue && getOptionTypes(children, true)}
            {getOptionTypes(children, false)}
          </>
        ) : (
          children
        )}
      </components.MenuList>
      {selectProps.isCSV && <Divider className="mt-half" />}
      {selectProps.isCSV && (
        <Button className="csv-button" variant="secondaryGray" onClick={selectProps.onCSVgeneration}>
          <FileDownloadOutlined className="mr-1" />
          <Typography color="gray-700" variant="text-sm" type="normal">
            {'Download CSV '}
          </Typography>
        </Button>
      )}
    </ItemGrid>
  ) : (
    <components.MenuList {...rest}>{children}</components.MenuList>
  )
}

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Search className="searchIcon mr-half" fill="#667085" />
    </components.DropdownIndicator>
  )
}

export const MultiValue = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <div className="create-select-dropdown-label create-select-menu-item-styles">
        <Typography className="mr-quarter" type="medium" color="gray-700" variant="text-xs">
          {props.data.label}
        </Typography>
        <Close className="create-select-icon-styles" onClick={() => props.removeProps.onClick()} />
      </div>
    </components.MultiValueRemove>
  )
}
